<template>
	<b-container fluid v-if="post" class="bg-primary">
		<b-container class="pt-2 pb-2">
			<b-row>
				<b-col>
					<b-link :to="post.path" class="text-white"
						><strong>Seneste nyheder:</strong> {{ post.title }}</b-link
					>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import {
	BContainer,
	BRow,
	BCol,
	BLink,
} from "bootstrap-vue";
export default {
	name: "LatestPostStreamer",
	components: {
		BContainer,
		BRow,
		BCol,
		BLink,
	},
	props: {
		days: {
			required: false,
			default: ()=>14,
		},
	},
	data() {
		return {
			post: false,
		};
	},
	async beforeCreate() {
		let post = await this.$content("blog", {deep: true})
			.without(["body"])
			.sortBy("createdAt", "desc")
			.where({status: {$ne: "draft"}})
			.limit(1)
			.fetch();
		
		post = post[0];
		let tmp_date = new Date();
		tmp_date.setDate(tmp_date.getDate()-this.days);
		if (new Date(post.createdAt) < tmp_date) {
			post = false;
		}

		this.post = post;
	},
}
</script>